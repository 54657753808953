<template>
  <table-view
    ref="tableView"
    url="/dispatch/lowerConfirm/list"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    :split-index="2"
    selectable
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-radio-group v-model="dataForm.day" @change="changeDateType">
        <el-radio label="1">今日</el-radio>
        <el-radio label="2">明日</el-radio>
        <el-radio label="3">
          <el-date-picker
            v-model="dataForm.dispatchTime"
            type="date"
            value-format="timestamp"
            placeholder="任务时间"
            :disabled="dataForm.day !== '3'"
          ></el-date-picker>
        </el-radio>
      </el-radio-group>
      <el-input v-model="dataForm.siteName" placeholder="工地名称" style="margin-left: 10px;"></el-input>
      <el-input v-model="dataForm.baseName" placeholder="所属基地"></el-input>
      <el-button @click="query" type="primary">查询</el-button>
      <el-button v-if="isAuthed('dispatch_decision_send')" @click="send" type="primary" :loading="loading">下发</el-button>
    </el-form>
    <template slot="append">
      <el-table-column
        header-align="center"
        align="center"
        prop="dispatcherUserName"
        label="所属公司">
      </el-table-column>
    </template>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'
// import CarListDropdown from '@/components/templates/car-list-dropdown'
export default {
  name: 'dispatch-record-send',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 11, prop: 'dispatchTime', label: '调运时间', minWidth: 150 },
        { id: 1, prop: 'siteName', label: '工地名称', minWidth: 250 },
        { id: 2, prop: 'constructionPart', label: '施工部位', width: 120 },
        {
          id: 3,
          prop: 'dispatchStatus',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // 1 待完成 2 进行中 3 已完成
            switch (cellValue) {
              case 1:
                return '待进行'
              case 2:
                return '进行中'
              case 3:
                return '已完成'
              case 4:
                return '失败'
              case 5:
                return '待下发'
              case 6:
                return '待确认'
              default:
                return '未知'
            }
          }
        },
        { id: 4, prop: 'baseName', label: '所属基地', width: 100 },
        { id: 5, prop: 'executeBaseName', label: '执行基地', width: 100 },
        { id: 6, prop: 'dispatchTime', label: '任务时间', width: 150 },
        { id: 7, prop: 'demandQuantityNumber', label: '方量数量', width: 100 },
        { id: 8, prop: 'demandCarSize', label: '需要车辆类型', width: 100 },
        { id: 9, prop: 'carSize', label: '实际车辆类型', width: 100 },
        { id: 10, prop: 'number', label: '实际车辆', width: 100 }
      ],
      dataForm: {
        day: '1',
        baseName: '',
        siteName: '',
        dispatchTime: ''
      },
      loading: false
    }
  },
  created () {
    this.dataForm.dispatchTime = this.getToday()
  },
  methods: {
    query () {
      if (this.dataForm.day === '1') {
        this.dataForm.dispatchTime = this.getToday()
      }
      if (this.dataForm.day === '2') {
        this.dataForm.dispatchTime = this.getTomorrow()
      }
      this.$refs.tableView.queryData()
    },

    changeDateType () {
      if (this.dataForm.day !== '3') {
        this.dataForm.dispatchTime = ''
      }
    },
    async send () {
      const selection = this.$refs.tableView.$refs.table.selection
      if (!selection.length) return this.$opts.alert('请先选择记录')
      this.loading = true
      try {
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/lowerConfirm/accept'),
          method: 'post',
          data: selection.map(s => s.dispatchTaskId)
        })
        this.loading = false
        if (data.code === 200) {
          this.$opts.success()
          this.query()
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    getToday () {
      var day = new Date()
      day.setTime(day.getTime())
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    },
    getTomorrow () {
      var day = new Date()
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000)
      return day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
    }
  }
}
</script>
